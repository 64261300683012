<template>
  <div class="scoreMainView">
    <div>
      <img class="imgStyle" src="../../assets/optionIcon.png" />

      <el-select
        v-model="currentEvent"
        placeholder="请选择"
        class="optionStyle"
        @change="changeView"
      >
        <el-option
          v-for="item in events"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>

    <el-table
      :data="rankList"
      stripe
      :row-class-name="tableRowClassName"
      :cell-style="tableRowStyle"
      :header-cell-style="{ background: '#092582', color: '#fff' }"
      empty-text="因 考试还未完全结束（含补考、延考） 或 监考核分工作未结束，排名奖项尚未公布。请收到公布通知后，再来查看。"
      class="tableStyle"
    >
      <el-table-column prop="real_num" label="全国排名" width="60">
      </el-table-column>

      <el-table-column prop="student_name" label="姓名" width="180">
      </el-table-column>

      <el-table-column prop="account_name" label="学校"> </el-table-column>

      <el-table-column prop="total_score" label="分数" width="60">
      </el-table-column>

      <el-table-column prop="award_show_lable" label="奖项"> </el-table-column>

      <el-table-column prop="submit_time" label="交卷时间"> </el-table-column>
    </el-table>

    <!-- <div class="eventContainer">
            <div :class="{'eventItem':true,'choose':currentEvent===item,'unchoose':currentEvent!==item}" v-for="item in events" :key="item" @click="chooseEvent(item)">
                <img class="eventImg" :src="require('../../assets/'+handleEventName(item)+'.png')" v-if="currentEvent!==item"/>
                
                <img class="eventImg" src="../../assets/chooseEvent.png" v-else/>
                <div class="txt">{{handleEventName(item)}}</div>
            </div>
        </div>
        <div class="tip">
            <div class="item">
            This page shows the preliminary score. If any question error occurs, the score will need to be adjusted. Please find your final score and award on the score page after four objective tests.
            </div>
        </div>
        <div class="rankList">
            <div class="outContainer">
                <div v-for="(item,index) in rankList" :key="index" class="rankItem">
                    <div class="itemTxt">{{item.name}}</div>
                    <div v-for="x in 10" :key="x" :class="{'scoreItem':true,'gray':x%2!==0}">
                        <div class="container" v-if="item.rank[x-1]">
                            <div style="margin-right:3px">
                                <span class="circle">{{item.rank[x-1].rank}}</span>
                            </div>
                            <div style="width:60%;overflow:hidden;margin-right:2px">{{item.rank[x-1].account_name}}</div>
                            <div style="width:22%;overflow:hidden">{{item.rank[x-1].student_name}}</div>
                            <div style="width:12%">{{item.rank[x-1].score+'分'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="personalScore">
                <div class="txt">Individual Results</div>
                <div class="indiContainer">
                    <div v-for="(item,index) in personalScores" :key="index" class="item">
                        <div style="font-size:0.8vw;background: rgb(230,234,241);">{{item.name}}</div>
                        <div style="font-size:2vh;margin-top:1vh;">{{item.score?item.score:0}}</div>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>
import {
  getScoresByEvent,
  getScoresSelf,
  getEventScores,
  getProjectEvent
} from "../../api/index";
import { getUserId, getProjectCode } from "../../utils/store";
import "../../assets/common/font.css";

export default {
  data() {
    return {
      events: [
        
      ],
      currentEvent: '',
      rankList: [],
      personalScores: [],
      

      value: "",
    };
  },
  mounted() {
    // this.getScoresByEvent()
    // this.getScoresSelf()
 
    // this.getEventScores();
    this.getProjectEvent();
  },
  methods: {
    getProjectEvent(){
      getProjectEvent(getProjectCode()).then((res) => {

        this.events = res.data;
        if (this.events.length > 0){
          this.currentEvent = this.events[0]
        }
        this.getEventScores();
      });


    },
    getEventScores() {
      getEventScores(this.currentEvent, getProjectCode()).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.rankList = res.data.data;
        }
      });
    },

    handleEventName(event) {
      let event_array = event.split(" ");
      return event_array[0];
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "success-row";
      } else {
        return "strong";
      }
    },

    tableRowStyle({ row, rowIndex }) {
      return "font-family: Montserrat-SemiBold;";
    },
    changeView() {
      this.getEventScores();
      console.log(1111, this.value);
    },
    getScoresByEvent() {
      getScoresByEvent(this.currentEvent).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.rankList = res.data.data;
        }
      });
    },
    getScoresSelf() {
      getScoresSelf(this.currentEvent, "608245c4a4640b84328b4575").then(
        (res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.personalScores = res.data.data;
          }
        }
      );
    },
    chooseEvent(item) {
      this.currentEvent = item;
      this.getScoresByEvent();
      this.getScoresSelf();
    },
  },
};
</script>

<style lang="less">
.scoreMainView {
  position: relative;
  width: 1100px;
  border-radius: 40px;
  margin: 0 auto;
  .imgStyle {
    position: absolute;
    margin-top: 30px;
    margin-left: 0px;
    width: 25px;
    height: 22.5px;
  }
  .optionStyle {
    margin-top: 20px;
    font-family: Montserrat-SemiBold;
  }
  .tableStyle {
    position: absolute;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
  }
  .el-table .success-row {
    background: #97afff;
  }
  .el-table .strong {
    background: #beceff;
  }
}
</style>
